import React, { useEffect, useReducer, useCallback } from "react";
import { useSession } from "../context/SessionContext";
import axios from 'axios';
import EditSpin from '../components/EditSpin';
import "./History.css";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Action types
const FETCH_SPINS = 'FETCH_SPINS';
const UPDATE_SPIN = 'UPDATE_SPIN';
const DELETE_SPIN = 'DELETE_SPIN';
const SET_EDITING_SPIN = 'SET_EDITING_SPIN';

// Reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_SPINS:
      return { ...state, spins: action.payload, frequency: calculateFrequency(action.payload) };
    case UPDATE_SPIN:
      const updatedSpins = state.spins.map(spin => 
        spin._id === action.payload._id ? action.payload : spin
      );
      return { 
        ...state, 
        spins: calculateRunningTotal(updatedSpins),
        frequency: calculateFrequency(updatedSpins),
        editingSpin: null 
      };
    case DELETE_SPIN:
      const filteredSpins = state.spins.filter(spin => spin._id !== action.payload);
      return { 
        ...state, 
        spins: calculateRunningTotal(filteredSpins),
        frequency: calculateFrequency(filteredSpins),
        editingSpin: null 
      };
    case SET_EDITING_SPIN:
      return { ...state, editingSpin: action.payload };
    default:
      return state;
  }
};

const calculateRunningTotal = (spins) => {
  let total = 0;
  return spins.map((spin) => {
    if (spin.outcome === 'W') {
      total += spin.bet_amt;
    } else if (spin.outcome === 'L') {
      total -= spin.bet_amt;
    }
    return { ...spin, running_total: total };
  });
};

const calculateFrequency = (spins) => {
  const initialFreq = {
    0: 0, "00": 0,
    ...Object.fromEntries(Array.from({ length: 36 }, (_, i) => [(i + 1).toString(), 0]))
  };

  return spins.reduce((acc, spin) => {
    acc[spin.spin_value] = (acc[spin.spin_value] || 0) + 1;
    return acc;
  }, initialFreq);
};

const History = () => {
  const { session } = useSession();
  const [state, dispatch] = useReducer(reducer, {
    spins: [],
    frequency: {},
    editingSpin: null
  });

  const fetchSpins = useCallback(async () => {
    if (!session) return;
    
    try {
      const response = await axios.get(`${backendUrl}/api/spins`, {
        params: { session_id: session._id }
      });
      const sortedData = response.data.sort((a, b) => a._id.localeCompare(b._id));
      const updatedSpins = calculateRunningTotal(sortedData);
      dispatch({ type: FETCH_SPINS, payload: updatedSpins });
    } catch (error) {
      console.error("Error fetching spins:", error);
    }
  }, [session]);

  useEffect(() => {
    fetchSpins();
  }, [fetchSpins]);

  const handleEditSpin = (spin) => {
    dispatch({ type: SET_EDITING_SPIN, payload: spin });
  };

  const handleUpdateSpin = async (updatedSpin) => {
    try {
      const response = await axios.put(`${backendUrl}/api/spins/${updatedSpin._id}`, updatedSpin, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.status === 200) {
        dispatch({ type: UPDATE_SPIN, payload: updatedSpin });
      }
    } catch (error) {
      console.error('Failed to update spin:', error);
    }
  };

  const handleDeleteSpin = async (spinId) => {
    if (window.confirm('Are you sure you want to delete this spin?')) {
      try {
        const response = await axios.delete(`${backendUrl}/api/spins/${spinId}`);
  
        if (response.status === 200 && response.data.deletedSpin) {
          dispatch({ type: DELETE_SPIN, payload: response.data.deletedSpin._id });
          console.log("Spin deleted successfully:", response.data.deletedSpin);
        } else {
          console.error("Unexpected response format:", response);
        }
      } catch (error) {
        console.error('Failed to delete spin:', error.response ? error.response.data : error.message);
      }
    }
  };

  const sortedFrequency = Object.entries(state.frequency)
    .sort((a, b) => b[1] - a[1])
    .map(([number, count]) => ({ number, count }));

  return (
    <div className="history-tab">
      <div className="history-left">
        <div className="spin-history-header">
          <div>No.</div>
          <div>Spin</div>
          <div>W/L</div>
          <div>Bet</div>
          <div>RT</div>
        </div>
        <div className="spin-history-grid">
          {state.spins.map((spin, index) => (
            <div key={spin._id} className={`spin-record ${getColor(spin.spin_value)}`}>
              <div className="spin-number">{index + 1}</div>
              <div
                className={`spin-value ${getColor(spin.spin_value)}`}
                onClick={() => handleEditSpin(spin)}
              >
                {spin.spin_value}
              </div>
              <div className={`outcome ${spin.outcome}`}>{spin.outcome === 'NB' ? '---' : spin.outcome}</div>
              <div className="bet-amount">
                {spin.outcome === 'NB' 
                  ? '' 
                  : `${spin.outcome === 'L' ? '-' : ''}${spin.bet_amt}`}
              </div>
              <div className="running-total">{spin.running_total}</div>
            </div>
          ))}
        </div>
        {state.editingSpin && (
          <EditSpin
            spin={state.editingSpin}
            onUpdate={handleUpdateSpin}
            onClose={() => dispatch({ type: SET_EDITING_SPIN, payload: null })}
            onDelete={handleDeleteSpin}
          />
        )}
      </div>

      <div className="history-right">
        <div className="frequency-columns">
          {sortedFrequency.map(({ number, count }, index, array) => {
            const totalItems = array.length;
            const itemsPerColumn = Math.ceil(totalItems / 2);
            const column = index < itemsPerColumn ? 2 : 1;
            const row = index < itemsPerColumn 
              ? itemsPerColumn - index 
              : totalItems - index;
            const style = {
              gridColumn: column,
              gridRow: row,
            };
            return (
              <div key={number} className="frequency-item" style={style}>
                <div className={`spin-count-circle ${getColor(number)}`}>
                  {number}
                </div>
                <span>{count}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const getColor = (spinValue) => {
  const redNumbers = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
  const blackNumbers = ['2', '4', '6', '8', '10', '11', '13', '15', '17', '20', '22', '24', '26', '28', '29', '31', '33', '35'];
  if (spinValue === '0' || spinValue === '00') return 'green';
  if (redNumbers.includes(spinValue)) return 'red';
  if (blackNumbers.includes(spinValue)) return 'black';
  return '';
};

export default History;