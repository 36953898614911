import React, { useState } from 'react';
import styles from './EnterPreviousSpinsModal.module.css';
 
const EnterPreviousSpinsModal = ({ show, handleClose, handleAddSpin, previousSpins, handleSave }) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [newSpinValue, setNewSpinValue] = useState('');
  if (!show) return null;


  const handleEditClick = (index) => {
    setEditingIndex(index);
    setNewSpinValue(previousSpins[index].spin_value);
  };

  const handleEditChange = (e) => {
    setNewSpinValue(e.target.value);
  };

  const handleEditSave = (index) => {
    const updatedSpins = [...previousSpins];
    updatedSpins[index].spin_value = newSpinValue;
    setEditingIndex(null); // Exit edit mode
  };

  
  const spinNumbers = Array.from({ length: 36 }, (_, i) => (i + 1).toString());

return (
  <div className={styles.fullscreenOverlay}>
    <div className={styles.fullscreenContent}>
      <div className={styles.fullscreenHeader}></div>
      <div className={styles.fullscreenBody}>
        <div className={styles.spinButtonsContainer}>
        <div className={styles.zerosContainer}>
            <button
              key="0"
              className={`${styles.spinButton} ${styles[getButtonColorClass('0')]}`}
              onClick={() => handleAddSpin('0')}
            >
              0
            </button>
            <button
              key="00"
              className={`${styles.spinButton} ${styles[getButtonColorClass('00')]}`}
              onClick={() => handleAddSpin('00')}
            >
              00
            </button>
          </div>
          <div className={styles.otherNumbersContainer}>
              {spinNumbers.map((number, index) => (
                <React.Fragment key={number}>
                  <button
                    className={`${styles.spinButton} ${styles[getButtonColorClass(number)]}`}
                    onClick={() => handleAddSpin(number)}
                  >
                    {number}
                  </button>
                  {(index + 1) % 12 === 0 && index !== 35 && <hr className={styles.divider} />}
                </React.Fragment>
              ))}
            </div>
        </div>
        <div className={styles.previousSpinsContainer}>
            <ul className={styles.previousSpinsList}>
              {previousSpins.map((spin, index) => (
                <li key={index} className={`${styles.previousSpinItem} ${styles[getTextColorClass(spin.spin_value)]}`}>
                  {editingIndex === index ? (
                    <input
                      type="text"
                      value={newSpinValue}
                      onChange={handleEditChange}
                      onBlur={() => handleEditSave(index)}
                      maxLength={2}
                      autoFocus
                    />
                  ) : (
                    <span onClick={() => handleEditClick(index)}>
                      {spin.spin_value}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      <div className={styles.fullscreenFooter}>
        <button className={`${styles.btnPrimary} ${styles.cancel} ${styles.darkgray}`} onClick={handleClose}>Cancel</button>
        <button className={`${styles.btnPrimary} ${styles.save} ${styles.green}`} onClick={handleSave}>Save</button>
      </div>
    </div>
  </div>
);

};

const getButtonColorClass = (spinValue) => {
  const redNumbers = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
  const blackNumbers = ['2', '4', '6', '8', '10', '11', '13', '15', '17', '20', '22', '24', '26', '28', '29', '31', '33', '35'];
  if (spinValue === '0' || spinValue === '00') return 'greenButton';
  if (redNumbers.includes(spinValue)) return 'redButton';
  if (blackNumbers.includes(spinValue)) return 'blackButton';
  return 'grayButton';
};

const getTextColorClass = (spinValue) => {
  const redNumbers = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
  const blackNumbers = ['2', '4', '6', '8', '10', '11', '13', '15', '17', '20', '22', '24', '26', '28', '29', '31', '33', '35'];
  if (spinValue === '0' || spinValue === '00') return 'greenText';
  if (redNumbers.includes(spinValue)) return 'redText';
  if (blackNumbers.includes(spinValue)) return 'blackText';
  return 'grayText';
};

export default EnterPreviousSpinsModal;