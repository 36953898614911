import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSession } from '../context/SessionContext'; // Import useSession hook
import './UploadSpins.css'; // Import the CSS file

const UploadSpins = () => {
  const [spinsData, setSpinsData] = useState('');
  const navigate = useNavigate();
  const { session } = useSession(); // Access the session from the context
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const handleUpload = async () => {
    if (!session) {
      toast.error('No active session found');
      return;
    }

    const spinValues = spinsData.split('\n').map(value => value.trim()).filter(value => value);
    const validSpinValues = ["0", "00", ...Array.from({ length: 36 }, (_, i) => (i + 1).toString())];

    // Validate spin values
    for (let value of spinValues) {
      if (!validSpinValues.includes(value)) {
        toast.error('Please make sure you are only uploading valid spin values.');
        return;
      }
    }

    const spins = spinValues.map(value => ({
      spin_value: value,
      outcome: "NB", // Default outcome
      bet_amt: 0 // Default bet amount
    }));

    const data = {
      session_id: session._id,
      spins,
    };

    try {
      const response = await fetch(`${backendUrl}/api/spins/bulk`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        toast.success('Spins uploaded successfully');
        navigate('/settings');
      } else {
        const errorData = await response.json();
        toast.error(`Failed to upload spins: ${errorData.error}`);
      }
    } catch (error) {
      console.error('Failed to upload spins:', error);
      toast.error('Failed to upload spins. Please try again later.');
    }
  };

  return (
    <div className="upload-spins-container">
      <ToastContainer position="top-right" autoClose={3000} />
      <h2>Upload Spins</h2>
      <textarea
        value={spinsData}
        onChange={(e) => setSpinsData(e.target.value)}
        className="large-input-field"
        placeholder='Enter spin values here, one per line'
      />
      <div className="button-group">
        <button onClick={() => navigate('/settings')} className="btn-upload-cancel">
          Cancel
        </button>
        <button onClick={handleUpload} className="btn-upload">
          Upload
        </button>
      </div>
    </div>
  );
};

export default UploadSpins;