import React, { useEffect } from 'react';
import { useSession } from '../context/SessionContext';
import styles from './SpinSaver.module.css';

const SpinSaver = ({ show, handleClose, handleSave }) => {
  const { selectedSpins, setSelectedSpins } = useSession();

  useEffect(() => {
    if (show) {
      setSelectedSpins(selectedSpins);
    }
  }, [show]);

  if (!show) return null;

  const spinNumbers = ['0', '00', ...Array.from({ length: 36 }, (_, i) => (i + 1).toString())];

  const handleSpinClick = (number) => {
    setSelectedSpins((prevSelectedSpins) =>
      prevSelectedSpins.includes(number)
        ? prevSelectedSpins.filter((spin) => spin !== number)
        : [...prevSelectedSpins, number]
    );
  };

  const handleSaveClick = () => {
    handleSave(selectedSpins);
    handleClose();
  };

  return (
    <div className={styles.fullscreenOverlay}>
      <div className={styles.fullscreenContent}>
        <div className={styles.fullscreenHeader}>
          <h2>Current Spin Strategy</h2>
        </div>
        <div className={styles.fullscreenBody}>
          <div className={styles.spinButtonsContainer}>
            {spinNumbers.map((number) => (
              <button
                key={number}
                className={`${styles.spinButton} ${getButtonColorClass(number)} ${
                  selectedSpins.includes(number) ? styles.selected : ''
                }`}
                onClick={() => handleSpinClick(number)}
              >
                {number}
              </button>
            ))}
          </div>
        </div>
        <div className={styles.fullscreenFooter}>
          <button className={`${styles.btnPrimary} ${styles.cancel} ${styles.darkgray}`} onClick={handleClose}>
            Cancel
          </button>
          <button className={`${styles.btnPrimary} ${styles.save} ${styles.green}`} onClick={handleSaveClick}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const getButtonColorClass = (spinValue) => {
  const redNumbers = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
  const blackNumbers = ['2', '4', '6', '8', '10', '11', '13', '15', '17', '20', '22', '24', '26', '28', '29', '31', '33', '35'];
  if (spinValue === '0' || spinValue === '00') return styles.greenButton;
  if (redNumbers.includes(spinValue)) return styles.redButton;
  if (blackNumbers.includes(spinValue)) return styles.blackButton;
  return styles.grayButton;
};

export default SpinSaver;
