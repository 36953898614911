import React from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import { useSession } from './context/SessionContext';
import Home from './pages/Home';
import Spin from './pages/Spin';
import History from './pages/History';
import Settings from './pages/Settings';
import Sessions from './pages/Sessions';
import Spins from './pages/Spins';
import UploadSpins from './pages/UploadSpins';
import SpinSaverPage from './pages/SpinSaverPage';
import './App.css';

const App = () => {
  const { session } = useSession();

  return (
    <Router>
      <div className="app-container">
        <nav className="bottom-nav">
          <ul>
            <li>
            <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
                <i className="fas fa-home"></i>
              </NavLink>
            </li>
            <li>
            <NavLink to="/spin" className={({ isActive }) => (isActive ? 'active' : '')}>
                <i className="fas fa-compact-disc"></i>
              </NavLink>
            </li>
            <li>
            <NavLink to="/history" className={({ isActive }) => (isActive ? 'active' : '')}>
                <i className="fas fa-chart-bar"></i>
              </NavLink>
            </li>
            <li>
            <NavLink to="/spin-saver" className={({ isActive }) => (isActive ? 'active' : '')}>
                <i className="fas fa-bookmark"></i>
              </NavLink>
            </li>
            <li>
            <NavLink to="/settings" className={({ isActive }) => (isActive ? 'active' : '')}>
                <i className="fas fa-cog"></i>
              </NavLink>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/spin" element={session ? <Spin /> : <Settings />} />
          <Route path="/history" element={session ? <History /> : <Settings />} />
          <Route path="/history/:sessionId" element={<History />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/spin-saver" element={<SpinSaverPage />} />
          <Route path="/sessions" element={<Sessions />} />
          <Route path="/spins/:sessionId" element={<Spins />} />
          <Route path="/upload-spins" element={<UploadSpins />} /> {/* Add the new route */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;