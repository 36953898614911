import React, { useState } from 'react';
import './EditSpin.css';

const EditSpin = ({ spin, onUpdate, onClose, onDelete }) => {
  const [updatedSpin, setUpdatedSpin] = useState(spin);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    let newErrors = { ...errors };

    if (name === 'spin_value') {
      // Allow only integers for spin value
      if (/^\d*$/.test(value)) {
        updatedValue = value;
        delete newErrors.spin_value;
      } else {
        newErrors.spin_value = 'Please enter only whole numbers';
      }
    } else if (name === 'bet_amt') {
      // Allow numbers with up to two decimal places for bet amount
      if (/^\d*\.?\d{0,2}$/.test(value)) {
        updatedValue = value;
        delete newErrors.bet_amt;
      } else {
        newErrors.bet_amt = 'Please enter a valid number with up to two decimal places';
      }
    }

    setUpdatedSpin((prevSpin) => ({
      ...prevSpin,
      [name]: updatedValue,
    }));
    setErrors(newErrors);
  };

  const handleOutcomeChange = (outcome) => {
    setUpdatedSpin((prevSpin) => ({
      ...prevSpin,
      outcome: outcome,
    }));
  };

  const handleSave = () => {
    if (Object.keys(errors).length === 0) {
      onUpdate(updatedSpin);
      onClose();
    }
  };

  const handleDelete = () => {
    onDelete(spin._id);
    onClose();
  };

  return (
    <div className="edit-spin-modal">
      <div className="edit-spin-content">
        <h3>Edit Spin</h3>
        <div className="form-group">
          <label>Spin Value:</label>
          <input
            type="text"
            name="spin_value"
            value={updatedSpin.spin_value}
            onChange={handleChange}
          />
          {errors.spin_value && <span className="error">{errors.spin_value}</span>}
        </div>
        <div className="form-group">
          <label>Outcome:</label>
          <div className="outcome-buttons-container">
            <button
              className={`outcome-button ${updatedSpin.outcome === 'W' ? 'selected-win' : ''}`}
              onClick={() => handleOutcomeChange('W')}
            >
              Win
            </button>
            <button
              className={`outcome-button ${updatedSpin.outcome === 'L' ? 'selected-loss' : ''}`}
              onClick={() => handleOutcomeChange('L')}
            >
              Loss
            </button>
            <button
              className={`outcome-button ${updatedSpin.outcome === 'NB' ? 'selected-nb' : ''}`}
              onClick={() => handleOutcomeChange('NB')}
            >
              No Bet
            </button>
          </div>
        </div>
        <div className="form-group">
          <label>Bet Amount:</label>
          <input
            type="text"
            name="bet_amt"
            value={updatedSpin.bet_amt}
            onChange={handleChange}
          />
          {errors.bet_amt && <span className="error">{errors.bet_amt}</span>}
        </div>
        <div className="button-group">
          <button className="btn-update" onClick={handleSave} disabled={Object.keys(errors).length > 0}>
            Save
          </button>
          <button className="btn-cancel" onClick={onClose}>
            Cancel
          </button>
          <button className="btn-delete" onClick={handleDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSpin;