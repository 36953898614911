import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSession } from '../context/SessionContext';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal';
import styles from './Sessions.module.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Sessions = () => {
    const [sessions, setSessions] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedSessionId, setSelectedSessionId] = useState(null);
    const navigate = useNavigate();
    const { openPreviousSession } = useSession();

    useEffect(() => {
        fetchSessions();
    }, []);

    const fetchSessions = async () => {
        try {
            const response = await axios.get(`${backendUrl}/api/sessions`);
            // Sort sessions by createdOn date in descending order
            const sortedSessions = response.data.sort((a, b) => 
                new Date(b.createdOn) - new Date(a.createdOn)
            );
            setSessions(sortedSessions);
        } catch (error) {
            console.error('Error fetching sessions:', error);
        }
    };

    const formatDate = (dateString) => {
        const options = { month: '2-digit', day: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
    };

    const handleViewClick = (sessionId) => {
        navigate(`/spins/${sessionId}`);
    };
    const handleDownloadClick = async (sessionId) => {
        try {
            // Fetch the session details to get the session name
            const sessionResponse = await axios.get(`${backendUrl}/api/sessions/${sessionId}`);
            const sessionName = sessionResponse.data.name;

            // Get the current date and time in the desired format
            const currentDateTime = new Date().toISOString().replace(/[-:.]/g, '').slice(0, 15);

            // Generate the file name
            const fileName = `Spins for ${sessionName} - ${currentDateTime}.csv`;

            // Fetch the spins data and download the file
            const response = await axios.get(`${backendUrl}/api/spins/${sessionId}/spins/csv`, {
                responseType: 'blob', // Important
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Use the generated file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading CSV:', error);
        }
    };


    const handleNewSessionClick = () => {
        navigate('/settings');
    };

    const handleDeleteClick = (sessionId) => {
        setSelectedSessionId(sessionId);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = async () => {
        if (!selectedSessionId) return;

        try {
            await axios.delete(`${backendUrl}/api/sessions/${selectedSessionId}`);
            setSessions(sessions.filter((session) => session._id !== selectedSessionId));
            setShowDeleteModal(false);
        } catch (error) {
            console.error('Error deleting session:', error);
        }
    };

    const handleCloseModal = () => {
        setShowDeleteModal(false);
    };

    const handleOpenSession = async (sessionId) => {
        const confirmOpen = window.confirm("Are you sure you want to open this previous session?");
        if (confirmOpen) {
            try {
                await openPreviousSession(sessionId);
                navigate(`/history/${sessionId}`);
            } catch (error) {
                console.error('Error opening session:', error);
            }
        }
    };

    return (
        <div className={styles.sessionsContainer}>
            <h1>All Sessions</h1>
            <div className={styles.sessionsTableContainer}>
                <table className={styles.sessionsTable}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Location</th>
                            <th>Start Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sessions.map((session) => (
                            <tr key={session._id}>
                                <td data-label="Name">{session.name}</td>
                                <td data-label="Location">{session.location}</td>
                                <td data-label="Start Date">{formatDate(session.createdOn)}</td>
                                <td data-label="Actions">
                                    <div className={styles.actionsCell}>
                                        <button className={styles.iconButton} onClick={() => handleDownloadClick(session._id)}>
                                            <i className={`fa fa-download ${styles.eyeIcon}`}></i>
                                        </button>
                                        <button className={styles.iconButton} onClick={() => handleOpenSession(session._id)}>
                                            <i className={`fa fa-folder-open ${styles.folderIcon}`}></i>
                                        </button>
                                        <button className={styles.iconButton} onClick={() => handleDeleteClick(session._id)}>
                                            <i className={`fa fa-trash ${styles.trashIcon}`}></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <DeleteConfirmationModal
                show={showDeleteModal}
                handleClose={handleCloseModal}
                handleConfirm={handleConfirmDelete}
            />
        </div>
    );
};

export default Sessions;