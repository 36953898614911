// SpinSaverPage.js
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSession } from "../context/SessionContext";
import SpinSaver from "../components/SpinSaver";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./SpinSaverPage.css";

const SpinSaverPage = () => {
  const [savedSpins, setSavedSpins] = useState([]);
  const { session } = useSession();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('showSpinSaver') === 'true') {
      setShowSpinSaver(true);
    }
  }, [location.search]);

  const handleSaveSpinSaver = (selectedSpins) => {
    setSavedSpins(selectedSpins);
    navigate("/settings");
  };

  const handleCancelSpinSaver = () => {
    navigate("/settings");
  };

  return (
    <div className="spin-saver-container">
      <ToastContainer position="top-right" autoClose={3000} />
      <h1>Spin Saver</h1>
      <SpinSaver
        show={true}
        handleClose={handleCancelSpinSaver}
        handleSave={handleSaveSpinSaver}
        initialSelectedSpins={savedSpins} // Pass previously selected spins
      />
    </div>
  );
};

export default SpinSaverPage;